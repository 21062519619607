import { Fragment } from "react"

export default function Floats({ children, title, imgPos, imgSrc }) {

    if (!imgPos) { imgPos = 'right' }

    return (
        <div className='md:mb-12 mx-4 w-auto'>
            <div className='max-w-4xl mx-auto grid grid-rows-2 md:grid-rows-1 md:grid-cols-3  gap-8 md:gap-2 justify-center items-center'>
                {imgPos === 'left' ? (
                    <Fragment>
                        <div className='md:col-span-2 row-start-1 md:col-start-2 flex justify-center md:text-right flex-col'>
                            <h3 className='mb-4 font-bold text-2xl'>{title}</h3>
                            <span className=''>{children}</span>
                        </div>
                        <img src={imgSrc} className='mx-auto max-h-44 ' />
                    </Fragment>
                ) : (
                    <Fragment>
                        <div className='md:col-span-2 flex justify-center md:text-left flex-col'>
                            <h3 className='mb-4 font-bold text-2xl'>{title}</h3>
                            <span className=''>{children}</span>
                        </div>
                        <img src={imgSrc} className='mx-auto max-h-44 ' />
                    </Fragment>
                )}
            </div>
        </div>
    )



    /*   if (imgPos === 'right') {
           return (
               <div className='pb-12 mb-6 mx-4 w-auto'>
                   <div className='max-w-4xl mx-auto grid grid-rows-2 md:grid-rows-1 md:grid-cols-3 text-center  gap-8 md:gap-2 justify-center'>
                       
                   </div>
               </div>
           )
       }*/
}