
import Button from "./button.component"


export default function Card({ children }) {

    return (
        <div className="card rounded-2xl max-w-md shadow-md border border-blue">
            <div className="p-10 flex flex-col">
                {children}
            </div>
        </div>
    )
}