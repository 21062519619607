import SocialmediaItem from "./socialmediaItem.component"



export default function SocialmediaList({ data }) {

    return (
        <div className="flex flex-col items-center md:flex-row justify-center gap-2 md:gap-4">
            {data.map((link) => {
                return (
                    <SocialmediaItem item={link} />
                )
            })}
        </div>
    )
}