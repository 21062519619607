
import Hero from '../../components/UI/hero.component'

import FloatsList from '../../components/UI/floatsList.component';
import SocialmediaList from '../../components/UI/socialmedia/socialmediaList.component';
import { useState, useEffect } from 'react';

import { BsArrowDown } from 'react-icons/bs'

export default function Home() {

    const SOCIALMEDIA_LINKS = [
        {
            platform: 'instagram',
            account: 'enhace_music'
        },
        {
            platform: 'youtube',
            account: 'UCubg93oEOmFYIfvvUUvvVLA'
        },
        {
            platform: 'twitch',
            account: 'enhace_music'
        },
    ]

    const PARTNERS = {
        path: '/assets/partners/',
        data: [
            {
                img: 'sgf-logo.png',
                name: 'Schlossgrabenfest',
                url: 'https://www.schlossgrabenfest.de/'
            },
            {
                img: 'da-marketing-logo.png',
                name: 'Darmstadt Citymarketing',
                url: 'https://www.darmstadt-citymarketing.de/'
            },
            {
                img: 'krone-logo.png',
                name: 'Goldene Krone',
                url: 'https://www.goldene-krone.de/'
            },
            {
                img: 'stkproject-logo.png',
                name: 'STK Project',
                url: 'https://www.instagram.com/stkproject/'
            },
            {
                img: 'revive-logo.png',
                name: 'Revive',
                url: 'https://revive-event.com/'
            },

        ]
    }

    const [partnersIsOpen, setPartnersIsOpen] = useState(false)
    const [parterDisplayedItems, setPartnerDisplayedItems] = useState(3)

    const togglePartnerTab = () => {
        setPartnersIsOpen(!partnersIsOpen)
    }

    useEffect(() => {
        if (partnersIsOpen) {
            setPartnerDisplayedItems(99)
        } else {
            setPartnerDisplayedItems(3)
        }
    }, [partnersIsOpen])

    return (
        <div>
            <Hero />
            <section id='about' className='md:pb-12'>
                <FloatsList />
            </section>

            <section id='socials' className='w-full h-auto bg-black py-12 px-4'>
                <div className='text-white max-w-4xl mx-auto'>
                    <h3 className='text-2xl mb-4'>Interested in our work?</h3>
                    <span className='mb-8 block'>
                        See our work on our social media platforms
                    </span>
                    <SocialmediaList data={SOCIALMEDIA_LINKS} />
                </div>
            </section>

            <section id='partners' className='w-full my-16'>
                <div className='max-w-4xl mx-auto'>
                    <h3 className=' text-xl md:text-2xl'>Partners we have worked with</h3>
                    <div className={`flex flex-wrap gap-4 py-6 justify-center w-full overflow-hidden h-auto transition-all`}>
                        {PARTNERS?.data.slice(0, parterDisplayedItems).map(({ img, name, url, index }) => {
                            return (
                                <a href={url} key={name} target='_blank' className='' >
                                    <div className={`hover:after:content-["${name.replace(/\s+/g, '_')}"] after:transition-all after:absolute hover:after:-bottom-8 after:-bottom-4 relative rounded-lg max-w-sm flex justify-center items-center bg-white shadow-md h-32 w-64 border hover:shadow-xl hover:-translate-y-2 hover:border-teal transition-all`}>
                                        <img alt={`${name}'s logo`} className='p-6 h-full' src={`${PARTNERS.path}${img}`} />
                                    </div>
                                </a>
                            )
                        })}
                    </div>
                    <button className={`my-4 py-2 px-6 rounded-lg bg-yellow-300 hover:bg-yellow-400 transition-all text-black after:content-["${<BsArrowDown />}"]`} onClick={togglePartnerTab}>{partnersIsOpen ? 'Show less' : 'Show more'}</button>
                </div>
            </section>

            <section className='pt-16 pb-8 px-4 bg-black'>
                <div className='max-w-4xl mx-auto'>
                    <h3 className='mb-4 text-brandWhite text-lg md:text-2xl md:text-left font-bold'>Straight Outta Jungle Livestream: GRXTZ</h3>
                    <iframe className='w-full aspect-video rounded-md' src="https://www.youtube.com/embed/CocKtrbVU2Y" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>
            </section>
        </div>
    )
}