
import { FaInstagram, FaYoutube, FaTwitch } from 'react-icons/fa'

export default function SocialmediaItem({ item }) {

    let link = 'http://enhace.org/notfound'
    let socialImg = null

    switch (item.platform) {
        case 'instagram':
            link = `https://www.instagram.com/${item.account}`
            socialImg = <FaInstagram />
            break
        case 'youtube':
            link = `https://www.youtube.com/channel/${item.account}`
            socialImg = <FaYoutube />
            break
        case 'twitch':
            link = `https://www.twitch.tv/${item.account}`
            socialImg = <FaTwitch />
            break
    }


    return (
        <a href={link} className="flex items-center gap-2 hover:text-teal transition-all font-light">
            <div className="w-4 h-4" >{socialImg}</div>
            <span className='uppercase'>{item.platform}</span>
        </a>
    )
}