
import NotfoundDivider from '../../assets/notfoundDivider.svg'

export default function CardView({ children }) {

    return (
        <div className="min-h-[calc(100vh-15vh)] h-fit md:h-[calc(100vh-34vh)] relative flex justify-center ">
            <div className='md:max-w-3xl absolute mx-8 top-24 py-12 px-8 bg-white rounded-xl justify-center shadow-lg items-center'>
                {children}
            </div>
            <img src={NotfoundDivider} className='w-full object-cover top-0' />
        </div>
    )
}