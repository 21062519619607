import { Outlet, Link } from 'react-router-dom'
import Footer from '../Footer/footer.component'

export default function Navbar() {

    return (
        <div>
            <div className="w-full h-16 shadow-lg bg-black">
                <div className='navbar-items w-auto h-full mx-6 flex items-center' >
                    <Link to='/'>
                        <h1 className='font-bold text-2xl text-white'>Enhace</h1>
                    </Link>
                    <div className='navbar-links-container flex ml-auto gap-2 md:gap-4 text-sm md:text-base'>
                        <Link className='text-white hover:text-teal transition-all' to='/contact'>Contact</Link>
                        <Link className='text-white hover:text-teal transition-all' to='/video'>Videos</Link>
                        <Link className='text-white hover:text-teal transition-all' to='/events'>Events</Link>
                        <a href='/shop' className='text-white hover:text-teal transition-all'>Shop</a>
                    </div>
                </div>
            </div>
            <div>
                <Outlet />
            </div>
            <Footer />
        </div>

    )
}

/*
        <div>
            <div className="w-full h-16 shadow-lg bg-black">
                <div className='navbar-items w-auto h-full mx-6 flex items-center' >
                    <Link to='/'>
                        <h1 className='font-bold text-2xl text-white'>Enhace</h1>
                    </Link>
                    <div className='navbar-links-container flex ml-auto space-x-4'>
                        <Link className='text-white hover:text-teal transition-all' to='#about'>Info</Link>
                        <Link className='text-white hover:text-teal transition-all' to='#socials'>Socials</Link>
                    </div>
                </div>
            </div>
            <div>
                <Outlet />
            </div>
            <Footer />
        </div>

*/