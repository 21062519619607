
import { Link } from 'react-router-dom'

import NotfoundDivider from '../../assets/notfoundDivider.svg'

export default function Notfound() {


    return (
        <div className="flex justify-center h-fit min-h-[calc(100vh-32vh)] relative">
            <div className='absolute top-48 z-10 mx-8'>
                <div className="flex flex-col rounded-xl shadow-2xl md:p-16 p-8 bg-white hover:-m-1 border-blue hover:border-4 transition-all">
                    <h2 className="font-bold text-black md:text-8xl text-4xl">404</h2>
                    <span className="text-black">The site you are looking for can´t be found</span>
                    <Link to='/'>
                        <button className='m-4 py-4 px-8 bg-black text-white font-bold rounded-full border hover:bg-white hover:text-black transition-all'>Take me Back</button>
                    </Link>
                </div>
            </div>
            <img src={NotfoundDivider} className='w-full object-cover top-0' />
        </div>
    )
}