import { Link } from 'react-router-dom'

export default function Footer() {

    return (
        <div id='footer' className='h-fit w-full bg-black '>
            <div className='pt-20 pb-8 max-w-4xl mx-auto'>
                <ul className='text-white text-center font-light flex flex-col md:flex-row justify-evenly'>
                    <Link className='hover:text-teal transition-all' to='/impressum'>Impressum</Link>
                </ul>
                <span id='credit' className='text-white text-center text-xs opacity-70 mt-12 inline-block'>COPYRIGHT @ ENHACE 2022</span>
            </div>
        </div>
    )
}