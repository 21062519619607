
import { useEffect, useState } from 'react';
import { BsArrowDown } from 'react-icons/bs';

const VideoPage = () => {

    const [activeVideo, setActiveVideo] = useState({ name: 'STK PROJECT @ Schlossgrabenfest´23 // Tech & Bass House', videoId: '3BaBsio5St8' })

    const onGalleryClickHandler = (video) => {
        setActiveVideo(video)
    }

    return (
        <div className='bg-black flex flex-col  items-center py-4'>
            <div className='max-w-6xl w-5/6 flex flex-col'>
                <h2 className='text-brandWhite text-left text-md md:text-2xl'>{activeVideo.name}</h2>
                <div className='flex w-full h-full justify-center bg-black py-2'>
                    <VideoPlayerComponent videoId={activeVideo.videoId} />
                </div>
                <div className='py-4'>
                    <h2 className='text-brandWhite text-left py-2'>Weitere Videos</h2>
                    {activeVideo && <VideoGalleryComponent onClickHandler={onGalleryClickHandler} activeVideo={activeVideo} />}
                </div>
            </div>
        </div>
    )
}

export default VideoPage;

const VideoPlayerComponent = ({ videoId }) => {

    return (
        <div className='w-full'>
            <iframe className='w-full aspect-video rounded-md' src={`https://www.youtube.com/embed/${videoId}?autoplay=1`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
    )

}

const VideoGalleryComponent = ({ onClickHandler, activeVideo }) => {

    const videoData = [
        { name: 'Lucas Summer @ Castillo de Cote, Sevilla // House Live-Set', videoId: 'HcL53517Wzs' },
        { name: 'STK PROJECT @ Schlossgrabenfest´23 // Tech & Bass House', videoId: '3BaBsio5St8' },
        { name: 'Shockz - Straight Outta Jungle', videoId: 'CgWnvqXzsTo' },
        { name: 'STK PROJECT // Straight Outta Jungle`21', videoId: 'O_boTuBSmwY' },
        { name: "LUCAS SUMMER // Straight Outta Jungle'21", videoId: 'mbaVjRAewiY' },
        { name: "LOOSE // Straight Outta Jungle'21", videoId: 'yZ2k6-QcKvU' },
        { name: "ASOW // Straight Outta Jungle'21", videoId: 'y8TOfztc2kM' },
        { name: "SAME THOUGHTS // Straight Outta Jungle'21", videoId: '81sc2N9wm1g' },
        { name: "GRXTZ // Straight Outta Jungle'21", videoId: 'CocKtrbVU2Y' },
        { name: "Luis Krüger @ Halle02 - Heidelberg, Germany // Enhace Live Session", videoId: '_xFbPSIwVcs' },
        { name: "STK Project // Bass House 2021 - Enhace Live Session", videoId: 'AHl9S1JN7Cg' },
        { name: "Loose @ Linie Neun - Enhace Live Session", videoId: '2T7ZqY0H_-w' },
        { name: "Luis Krueger @ Linie Neun - Enhace Live Session", videoId: 'f6nztWgcvfI' },
        { name: "STK Project @ Linie Neun - Enhace Live Session", videoId: '2aECAKpVaNc' },
        { name: "Lucas Summer feat. Vescu @ VOKE EVENTS - Enhace Live Session", videoId: 'YhtcynEPGJ4' },
        { name: "STK Project @ VOKE EVENTS - Enhace Live Session", videoId: '0JSlzRto-sg' },
        { name: "Luis Krueger @ VOKE EVENTS - Enhace Live Session", videoId: '2jSe6QFOn_U' },
    ]

    const [showMoreVideos, setShowMoreVideos] = useState(false)
    const [videosDisplayed, setVideosDisplayed] = useState(6)

    const toggleShowMoreVideos = () => {
        setShowMoreVideos(!showMoreVideos)
    }

    const showMoreVideoCount = 6

    useEffect(() => {
        if (showMoreVideos) {
            setVideosDisplayed(99)
        } else {
            setVideosDisplayed(showMoreVideoCount)
        }
    }, [showMoreVideos])

    const filterdArray = videoData.filter(video => { return video.videoId !== activeVideo.videoId })
    const videoArray = filterdArray.slice(0, videosDisplayed)

    console.log(filterdArray.length)

    return (
        <div className='w-full'>
            <div className='w-full grid grid-col-1 md:grid-cols-3 gap-4'>
                {videoArray.map(video => {
                    return (
                        <div key={video.videoId} className='flex flex-col cursor-pointer' onClick={() => onClickHandler(video)}>
                            <img src={`https://img.youtube.com/vi/${video.videoId}/hqdefault.jpg`} className='rounded-md aspect-video object-cover hover:scale-105 border-2 border-transparent hover:border-blue transition-all' />
                            <h3 className='text-brandWhite pt-2 text-sm text-left'>{video.name}</h3>
                        </div>
                    )
                })}
            </div>
            {videoData && <button className={`my-4 py-2 px-6 rounded-lg bg-yellow-300 hover:bg-yellow-400 transition-all text-black after:content-["${<BsArrowDown />}"]`} onClick={toggleShowMoreVideos}>{showMoreVideos ? 'Show less' : 'Show more'}</button>}
        </div>
    )
}