import PERSONAL_INFO from '../../assets/undraw_personal_info.svg'
import CardView from "../../components/UI/cardview.component";

export default function Impressum() {

    return (
        <CardView>
            <div className='grid grid-rows-2 md:grid-rows-1 md:grid-cols-2 gap-8'>
                <div>
                    <div className="md:text-left grid grid-rows-2 gap-4">
                        <span>
                            <p className='font-bold'>Leon Marquez</p>
                            <p>August-Bebel-Straße 2a</p>
                            <p>64347 Griesheim</p>
                            <p>Deutschland</p>
                        </span>
                        <span>
                            <p className='font-bold'>Kontakt</p>
                            <p>August-Bebel-Straße 2a</p>
                            <p>64347 Griesheim</p>
                            <p>Deutschland</p>
                        </span>
                    </div>
                </div>
                <img src={PERSONAL_INFO} className='w-3/4 md:w-full md:p-4 mx-auto' />
            </div>
        </CardView>
    )
}