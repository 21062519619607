import Button from "../../components/UI/button.component";
import CardView from "../../components/UI/cardview.component";


import { FaMailBulk } from 'react-icons/fa'

export default function Contact() {

    return (
        <CardView>
            <div className='max-w-lg text-center'>
                <h2 className='text-2xl font-bold mb-4'>Contact us now!</h2>
                <p className='mb-4'>
                    Bereit dein nächstes Projekt, mit uns an deiner Seite, zu starten?
                    Kontaktiere uns doch per Mail oder schicke uns eine Nachricht auf Instagram.
                    Wir freuen uns auf dich!
                </p>
                <a href="mailto:enhace.music@gmail.com">
                    <Button>
                        <p className='flex whitespace-nowrap gap-2 items-center'><FaMailBulk />Kontakt per Mail</p>
                    </Button>
                </a>
            </div>
        </CardView>
    )
}