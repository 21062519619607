import './App.css';

import { Routes, Route, Navigate } from 'react-router-dom'

import Navbar from './components/Navbar/Navbar.component';
import Home from './routes/home/home.component';
import Playground from './routes/playground/playground.component';
import Notfound from './routes/notfound/notfound.component';
import Impressum from './routes/impressum/impressum.component';
import Contact from './routes/contact/contact.component';
import VideoPage from './routes/videos';
import EventsPage from './routes/events';
import ShopPage from './routes/shop';

function App() {
  return (
    <div className="App overflow-x-hidden">
      <Routes>
        <Route path='/' element={<Navbar />}>
          <Route path='' element={<Home />} />
          <Route path='playground' element={<Playground />} />
          <Route path='impressum' element={<Impressum />} />
          <Route path='contact' element={<Contact />} />
          <Route path='video' element={<VideoPage />} />
          <Route path='events' element={<EventsPage />} />
          <Route path='shop' element={<ShopPage />} />
          <Route path='notfound' element={<Notfound />} />
        </Route>

        <Route path="*" element={<Navigate to='/notfound' />} />
      </Routes>
    </div >
  );
}

export default App;
