
export default function Button({ children, style }) {

    const BUTTON_TYPES = {
        standard: 'bg-black text-white border-black hover:bg-white hover:text-black ',
        inverted: 'bg-white text-black border-black hover:bg-black hover:text-white',
        highlight: 'bg-blue text-white border-blue hover:bg-white hover:text-blue '
    }

    if (!style) style = 'standard'

    return <button className={`m-4 py-4 px-8 font-bold rounded-full border transition-all ${BUTTON_TYPES[style]} `}>{children}</button>
}