import { Fragment } from "react"

import Floats from './../../components/UI/floats.component';

import VIDEOGRAPH from '../../assets/undraw_videograph.svg'
import PHOTOSESSION from '../../assets/undraw_photo_session.svg'
import DUA from '../../assets/undraw_dua.svg'

export default function FloatsList() {

    return (
        <div className='flex gap-6 flex-col mt-4'>
            <Floats
                imgPos='right'
                imgSrc={VIDEOGRAPH}
                title='We capture every moment...'
            >
                Wir setzen dich, deine Show und dein Event in Szene und halten so alles im Bewegtbild für die Ewigkeit fest.
                Egal ob Live oder ein Nachbearbeiteter Clip, wir sind für jede Situation vorbereitet.
                Bei Bedarf, können wir dir eine Plattform bieten, um deine Aufnahmen zu veröffentlichen.
            </Floats>
            <Floats
                imgPos='left'
                imgSrc={PHOTOSESSION}
                title='Even still photos can tell a Story...'
            >
                Du benötigst Bilder von deinem Event für deinen nächsten Eyecatcher auf Instagram oder einfach nur um dich an spektakuläre Momente erinnern zu können und mit diesen werben zu können?
                Kein Problem! Wir begleiten dich gerne bei deinem nächsten Vorhaben.
            </Floats>
            <Floats
                imgPos='right'
                imgSrc={DUA}
                title='We bring the music to your party...'
            >
                Pssst - das war aber noch nicht alles.
                Von Zeit zu Zeit stehen wir auch selber hinter den Decks.
                Als kleines Hobby gestartet, wurde daraus eine neue Herausforderung, in welcher wir stetig weiter wachsen und uns entwickeln können.
                Mittlerweile bespielen wir regelmäßig im Rahmen einer eigenen Eventreihe den DJ-Booth der Goldene Krone in Darmstadt und konnten unter anderem für den Sykline Plaza in Frankfurt und die Burg Frankenstein im Odenwald Musik machen.
            </Floats>
        </div>
    )
}