
import { useEffect } from 'react';
import useExternalScripts from '../hooks/useExternalScripts';
import StoreComponent from '../components/Shop/ShopCompoenent';

export default function ShopPage() {

    useEffect(() => {

    })
    return (
        <div className="w-full h-fit py-2 px-4" >
            <h2 className='w-full font-bold text-4xl py-6 mx-auto'>Enhace Merch</h2>
            <StoreComponent storeId='84288860' />
        </div>
    )

}