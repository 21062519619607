import Card from "../../components/UI/card.button"
import Button from "../../components/UI/button.component"

export default function Playground() {

    return (
        <div className="flex mt-8 mx-8 items-center gap-4 flex-col md:flex-row ">
            <Card className='odd:background-black'>
                <h3 className="text-left font-bold text-xl mb-4">This is a Header</h3>
                <span className="text-left mb-8">Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto dolore nobis ut eum et incidunt culpa ab fuga perferendis? Voluptate aliquid nam sapiente perferendis eos, totam repellendus fuga qui id est. Velit est id similique dolorem quam porro vitae doloribus perferendis saepe, rerum accusantium fugiat perspiciatis hic culpa, eligendi aut praesentium quia repellendus cumque repellat, laboriosam nemo molestiae atque! Distinctio?</span>
                <Button >Test</Button>
                <Button style='inverted'>Test</Button>
                <Button style='highlight'>Test</Button>
            </Card>
            <Card className='odd:background-black'>
                <h3 className="text-left font-bold text-xl mb-4">This is a Header</h3>
                <span className="text-left mb-8">Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto dolore nobis ut eum et incidunt culpa ab fuga perferendis? Voluptate aliquid nam sapiente perferendis eos, totam repellendus fuga qui id est. Velit est id similique dolorem quam porro vitae doloribus perferendis saepe, rerum accusantium fugiat perspiciatis hic culpa, eligendi aut praesentium quia repellendus cumque repellat, laboriosam nemo molestiae atque! Distinctio?</span>
                <Button >Test</Button>
                <Button style='inverted'>Test</Button>
                <Button style='highlight'>Test</Button>
            </Card>
        </div>
    )
}