
import DIVIDER from '../../assets/divider.svg'
import MELLOW from '../../assets/undraw_mellow.svg'

export default function Hero() {


    return (
        <div id='header' className="h-fit select-none">
            <div className='bg-black relative z-10 h-fit overflow-y-visible' >
                <h2 className='relative  md:text-8xl font-bold text-white text-left text-5xl pl-8 pt-8 space-y-6 z-20 select-none'><p />Because <p /> music sounds<p />better with<p />you...</h2>
                <img className='absolute top-12 -right-8 h-4/6 -rotate-90 z-10' src={MELLOW} />
            </div>
            <img className='relative -z-10 w-full object-cover -translate-y-2' src={DIVIDER}></img>
        </div>
    )
}